<script setup lang="ts">
import { ErrorMessage, useField } from 'vee-validate'
import { formatFileSize } from '#imports'

// For legacy reasons, we default to enabling cropping.
const { enableCropping = true, currentUrl, clearValue, label, required, name } = defineProps<{
  name: string
  // Should the label mention that the field is optional or required?
  required?: boolean
  currentUrl?: string
  aspectRatio?: number
  minAspectRatio?: number
  // The value to set when the user clears the image
  clearValue?: any
  label?: string
  // The maximum width and height of the image to which the image will be resized
  maxSize?: { height: number, width: number }
  // Whether to enable image cropping
  enableCropping?: boolean
}>()

const emit = defineEmits<{
  (event: 'update-image', file: File | ContentFile | null): void
}>()

const { t } = useI18n({ useScope: 'local' })

// Note: this type might not always match with the form schema.
const { value: imageValue, setErrors } = useField<File | ContentFile | null>(name)

// Did the user clear the image?
const hasRemovedImage = ref(false)

// maybe we should make it into a file here or we should do it from the edit page
const uploadedFile = ref<FileList | null>()
const isImageCropModalOpen = ref(false)

const rawImageUrl = computed(() => {
  if (process.client && uploadedFile.value?.item?.(0)) {
    return URL.createObjectURL(uploadedFile.value?.item(0) as Blob)
  }
  return null
})

function isFile(value: unknown): value is File {
  return value instanceof File
}

// For persisted files
const persistedFileUrl = computed(() => {
  const value = imageValue.value

  if (!value) {
    if (hasRemovedImage.value) return undefined
    // If there's no value set for the field, and the user didn't clear the field
    // use the 'currentUrl' to show the image, is present (if can also be undefined).
    return currentUrl
  }

  if (typeof value === 'object' && 'fileUrl' in value) {
    return (value as ContentFile).fileUrl
  }
  // This happens when the form value is set to the current url. For example, in the vacanancy form.
  // We could also check if the string is an actual URL, like we do in the custom `imageUploadField` yup field.
  else if (typeof value === 'string') {
    return value
  }
  else if (isFile(value)) {
    return value.name
  }
  else {
    return undefined
  }
})

const persistedFileName = computed(() => {
  if (!persistedFileUrl.value) return null

  let url: URL
  try {
    url = new URL(persistedFileUrl.value)
  }
  catch (error) {
    console.error(`Error parsing URL: ${persistedFileUrl.value}`)
    return null
  }

  // Get the "response-content-disposition" parameter
  const disposition = url.searchParams.get('response-content-disposition')

  if (disposition) {
    // Extract the filename from the "response-content-disposition" parameter
    const match = /filename\*?=(.*?)(?:$|;)/.exec(disposition)
    return match
      ? decodeURIComponent(match[1].replace(/^"|"$/g, ''))
      : null
  }
})

const hasImage = computed(() => !!persistedFileUrl.value)

const cancelImageSelection = () => {
  isImageCropModalOpen.value = false
  uploadedFile.value = null
}

const changeImage = (event: Event) => {
  const target = event.target as HTMLInputElement
  const file = target.files?.item(0) ?? null
  if (!file) {
    console.error(`No file selected?`)
    return
  }

  if (enableCropping) {
    // Update the form field value
    imageValue.value = file
    isImageCropModalOpen.value = true
  }
  else {
    // If cropping is disabled, directly set the file
    const files = createFileList(file)
    uploadedFile.value = files
    imageValue.value = files.item(0)
    emit('update-image', files.item(0))
  }
}

const removeImage = () => {
  emit('update-image', null)
  // Note: the type of `props.clearValue` is any, and defaults to undefined.
  // This doesn't match the `null` type of `imageValue.value` but in practice it works fine.
  imageValue.value = clearValue
  hasRemovedImage.value = true
  setErrors('')
}

const createFileList = (originalFile: File, fileName?: string) => {
  const file = new File([originalFile], originalFile.name ?? fileName, {
    type: originalFile.type ?? 'image/png',
  })

  // We cannot append to the existing uploadedFile FileList object so we have to create a new one and replace
  const dataTransfer = new DataTransfer()
  dataTransfer.items.add(file)
  return dataTransfer.files
}

const cropImage = (croppedCanvas: HTMLCanvasElement) => {
  if (!croppedCanvas) return

  croppedCanvas.toBlob((blob) => {
    if (blob) {
      const file = new File([blob], uploadedFile.value?.item(0)?.name ?? 'cropped.png', {
        type: 'image/png',
      })
      const files = createFileList(file)

      isImageCropModalOpen.value = false
      uploadedFile.value = files
      imageValue.value = files.item(0)
      emit('update-image', files.item(0))
    }
  }, 'image/png')
}

</script>

<template>
  <div class="col-span-full flex flex-col">
    <BaseLabel>
      {{ label || t('label' + (required ? '.required' : '.optional')) }}
    </BaseLabel>

    <div class="max-w-xl">
      <BaseInputFileHeadless
        v-slot="{ open, remove, preview, drop, files }"
        v-model="uploadedFile"
        accept="image/*"
        @change="changeImage"
      >
        
        <ImagePreview
          v-if="!uploadedFile && !rawImageUrl && hasImage"
          :file-url="persistedFileUrl"
          :file-name="persistedFileName"
          @remove="removeImage"
        />

        
        <div
          v-else
          @dragenter.stop.prevent
          @dragover.stop.prevent
          @drop="drop"
        >
          <div
            v-if="!files?.length"
            class="nui-focus border-muted-300 dark:border-muted-700 hover:border-muted-400 focus:border-muted-400 dark:hover:border-muted-600 dark:focus:border-muted-700 group cursor-pointer rounded-lg border-[3px] border-dashed p-8 transition-colors duration-300"
            tabindex="0"
            role="button"
            @click="open"
            @keydown.enter.prevent="open"
          >
            <div class="p-5 text-center">
              <Icon
                name="mdi-light:cloud-upload"
                class="text-muted-400 group-hover:text-primary-500 group-focus:text-primary-500 mb-2 size-10 transition-colors duration-300"
              />

              <h4 class="text-muted-400 font-sans text-sm">
                {{ t('drop') }}
              </h4>

              <div>
                <span
                  class="text-muted-400 font-sans text-[0.7rem] font-semibold uppercase"
                >
                  {{ $t('or') }}
                </span>
              </div>

              <label
                for="file"
                class="text-muted-400 group-hover:text-primary-500 group-focus:text-primary-500 cursor-pointer font-sans text-sm underline underline-offset-4 transition-colors duration-300"
              >
                {{ t('select') }}
              </label>
            </div>
          </div>

          
          <ImagePreview
            v-else
            :file-name="files?.item(0)!.name"
            :file-size="formatFileSize(uploadedFile?.item(0)!.size)"
            :file-url="preview(files.item(0)).value"
            @remove="
              () => {
                remove(files.item(0)!)
                removeImage()
              }
            "
          />
        </div>
      </BaseInputFileHeadless>
      <CropImageModal
        v-if="enableCropping"
        size="lg"
        :aspect-ratio="aspectRatio"
        :min-aspect-ratio="minAspectRatio"
        :open="isImageCropModalOpen"
        :image-url="rawImageUrl"
        :max-size="maxSize"
        @close="cancelImageSelection"
        @crop="cropImage"
      />
    </div>
    <ErrorMessage :name="name" class="mt-1 text-[10px] text-red-500" />
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "label": {
      "optional": "Upload an image or photo (optional)",
      "required": "Upload an image or photo"
    },
    "drop": "Drop file to upload",
    "select": "Select a file"
  },
  "nl": {
    "label": {
      "optional": "Upload een afbeelding of foto (optioneel)",
      "required": "Upload een afbeelding of foto"
    },
    "drop": "Sleep hier een bestand naartoe om te uploaden",
    "select": "Selecteer een bestand"
  }
}
</i18n>
